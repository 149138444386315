<!-- 首页-->
<template>
  <div>
    <scroll height="550px"></scroll>
    <digLibrary></digLibrary>
    <wraparound></wraparound>
    <characteristic></characteristic>
    <activity></activity>
    <dep></dep>
    <callus></callus>
    <foot></foot>
  </div>
</template>

<script>
import scroll from "./components/scroll.vue";
import digLibrary from "./components/digLibrary.vue";
import wraparound from "./components/wraparound.vue";
import characteristic from "./components/characteristic.vue";
import activity from "./components/activity.vue";
import dep from "./components/dep.vue";
import callus from "./components/callus.vue";
import foot from "./components/foot.vue";
export default {
  name: "",
  components: { scroll, digLibrary, wraparound, characteristic, activity, dep, callus, foot },
  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped></style>
